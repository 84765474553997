import {
  AudioConfig,
  SpeechConfig,
  SpeechRecognizer
} from 'microsoft-cognitiveservices-speech-sdk';
import {
  CHANNEL_TYPES,
  CONTEXTUAL_OPERATION_TYPE,
  IContextualContact,
  IInteraction,
  INTERACTION_DIRECTION_TYPES,
  INTERACTION_STATES,
  ITranscript,
  IUserDetails,
  LOG_LEVEL,
  RecordItem,
  USER_TYPES,
  contextualOperation,
  registerClickToDial,
  registerContextualControls,
  sendNotification,
  setAppHeight,
  setInteraction
} from "@amc-technology/davinci-api";
import {
  Call,
  DtmfTone,
  Features,
  IncomingCall,
  TeamsCallAgent,
  TransferToParticipantLocator,
  TransferToParticipantOptions
} from '@azure/communication-calling';
import {
  CallTransfer20Filled,
  Dialpad20Filled,
  Pause20Filled,
  Pause20Regular,
} from '@fluentui/react-icons';
import {
  ControlBar,
  ControlBarButton,
  DEFAULT_COMPONENT_ICONS,
  EndCallButton,
  FluentThemeProvider,
  MicrophoneButton,
  VideoTile
} from '@azure/communication-react';
import {
  initializeIcons,
  registerIcons
} from '@fluentui/react';

import ClickToActComponent from './ClickToActComponent';
import { DTMFdigits } from './Models/DTMFevents.enum';
import { IncomingCallToast } from './IncomingCallComponent';
import React from 'react';
import { StorageService } from './StorageService';
import { bind } from 'bind-decorator';
import { getDisplayName } from './GraphService';

initializeIcons();
registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

const TEAMS_DIRECTION_MAP = {
  'Incoming' : INTERACTION_DIRECTION_TYPES.Inbound,
  'Outgoing' : INTERACTION_DIRECTION_TYPES.Outbound
}

const DTMF_MAP: {[key: string]: DTMFdigits} = {
  '0': DTMFdigits.ZERO,
  '1': DTMFdigits.ONE,
  '2': DTMFdigits.TWO,
  '3': DTMFdigits.THREE,
  '4': DTMFdigits.FOUR,
  '5': DTMFdigits.FIVE,
  '6': DTMFdigits.SIX,
  '7': DTMFdigits.SEVEN,
  '8': DTMFdigits.EIGHT,
  '9': DTMFdigits.NINE,
  '*': DTMFdigits.STAR,
  '#': DTMFdigits.POUND,
};

interface props {
  callAgent: any;
  resize: any;
  setTeamsPresence: any;
  logger: any;
  transcriptionConfig: any;
  cognitiveServicesConfig: any;
  minAppHeight: number;
  authProvider: any;
  agentDetails: IUserDetails;
  id: string;
}

interface state {
    isOnCall: boolean;
    isCallOnHold: boolean;
    incomingCallAlert: boolean;
    audioButtonChecked: boolean;
    dialpadInputValue: string;
    currentCallId: string;
    showDialPad: boolean;
    currentCallState: INTERACTION_STATES | undefined;
    incomingCallList: {
      [key: string]: any;
    };
    callList: {
      [key: string]: any;
    };
    interactionList: {
      [scenarioId: string]: IInteraction;
  };
}
let globalIncomingCallHandler: any;
export class CallComponent extends React.Component<props, state> {
  stackTokens: any;
  dialStyle: any;
  dialPadBtn: any;
  controlBarStyles: any;
  videoTileStyles: any;
  displayName: string;
  callAgent: TeamsCallAgent;
  transferAgent: any;
  resize: any;
  storageService: any;
  clickToAct: any;
  transcriptionConfig: any;
  remoteAudioStarted: boolean;
  minAppHeight: number;
  agentAttributes: any;
  agentPhoneNumber: string;

  constructor(props: any) {
    const functionName = 'constructor';
    super(props);
    try {
      this.minAppHeight = props.minAppHeight ?? 5;
      globalIncomingCallHandler = this.incomingCallHandler;
      this.transferAgent = {};

      this.setAgentNumber();
      this.displayName = '';
      this.remoteAudioStarted = false;
      // TODO: This needs to be moved up to the index.tsx file and passed as props since these are just helper functions
      this.storageService = new StorageService(props);
      // TODO: This needs to be moved up to the index.tsx file and passed as props since these are just helper functions
      this.clickToAct = new ClickToActComponent({
        logger:this.props.logger,
        acceptCall:this.acceptCall,
        blindTransfer: this.blindTransfer,
        rejectCall: this.rejectCall,
        hangupCall: this.endCall,
        holdCall: this.holdCall,
        resumeCall: this.resumeCall,
        setTeamsPresence: this.props.setTeamsPresence,
        unmuteCall: this.unmuteCall,
        muteCall: this.muteCall,
        handleDTMF: this.handleDTMF
      });
      this.clickToAct.initApi();
      this.state = {
        isOnCall: false,
        isCallOnHold: false,
        incomingCallAlert: false,
        audioButtonChecked: true,
        currentCallState: undefined,
        dialpadInputValue: '',
        incomingCallList:{},
        callList:{},
        currentCallId:'',
        showDialPad: false,
        interactionList: {}
      };
      this.checkForExpiredCalls();
      this.stackTokens = { childrenGap: 40 };
      this.dialPadBtn = {backgroundColor: '#fff' };
      this.dialStyle = { root: { margin: 0, border: 'solid .5px', paddingBottom: '36px', paddingTop:'1.1rem', paddingRight: '1.1rem', paddingLeft: '1.1rem', maxWidth: 'inherit', minWidth: '121%' } };
      this.controlBarStyles = { root: {border: '0.5px solid', borderRadius: '3px', width: 'fit-content', maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto'} };
      this.videoTileStyles = {
          root: { minHeight: '230px', width: '100%', border: '1px solid #999', marginLeft: 'auto'},
          displayNameContainer: {marginLeft: 'auto', marginRight: 'auto'}
        };
      if (props.callAgent) {
          props.callAgent?.on('incomingCall', (args: any) => {
              globalIncomingCallHandler(args);
          });
      }
      registerClickToDial(this.clickToDial);
      registerContextualControls(this.startCall);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  private setAgentNumber() {
    const functionName = 'setAgentNumber';
    try {
      this.agentAttributes = this.props.agentDetails?.attributes != "" ? JSON.parse(this.props.agentDetails?.attributes) : [];
        for (let i = 0; i < this.agentAttributes.length; i++) {
          if (this.agentAttributes[i].name === 'Phone Number') {
            this.agentPhoneNumber = this.agentAttributes[i].value;
          }
        }
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

   /**
   * Starts a call initiated by click to dial
   * @param {stringy} phoneNumber - phone number to be called
   * @memberof CallComponent
   */
  @bind
  async clickToDial(phoneNumber: string) {
    const functionName = 'clickToDial';
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      let numberDialed: IContextualContact = {
        channels: [],
        displayName: phoneNumber,
        uniqueId: phoneNumber
      }
      this.startCall(numberDialed);

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
   * Receives incoming call from Microsoft Teams and handles it
   * @param {IncomingCall} incomingCall - incoming call object
   * @memberof CallComponent
   */
  @bind
  incomingCallHandler(args: { incomingCall: IncomingCall }) {
    const functionName = 'incomingCallHandler';
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`, args.incomingCall);
      const currentCallId = args.incomingCall.id;
      this.setState({currentCallId: currentCallId});
      this.setState({incomingCallAlert: true});
      if(!this.state.incomingCallList.hasOwnProperty(args.incomingCall.id)) {
        const incomingCallList = this.state.incomingCallList;
        incomingCallList[args.incomingCall.id] = args.incomingCall;
        this.setState({incomingCallList: incomingCallList})
        this.displayName = incomingCallList[args.incomingCall.id].callerInfo.displayName? incomingCallList[args.incomingCall.id].callerInfo.displayName : '';
        this.setState({currentCallState: INTERACTION_STATES.Alerting});
        this.prepareAndSetInteraction(incomingCallList[currentCallId]);
        // Subscribe to callEnded event and get the call end reason
        incomingCallList[args.incomingCall.id].on('callEnded', (args: any) => {
            this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : Incoming Call Ended`);
            this.setState({isOnCall: false});
            this.setState({incomingCallAlert: false});
            this.setState({currentCallState: INTERACTION_STATES.Disconnected});
            setAppHeight(this.minAppHeight);
            this.prepareAndSetInteraction(incomingCallList[currentCallId]);
            // callEndReason is also a property of IncomingCall
            // var callEndReason = incomingCallList[args.incomingCall.id].callEndReason;
        });
      }

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
   * Start outbound call
   * @param {string} number - optional parameter - number to call
   * @memberof CallComponent
   */
  @bind
  async startCall(contact: IContextualContact) {
    const functionName = 'startCall';
    try {
      const callList = this.state.callList;
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);
      let callTarget;
      this.displayName = '';
      const phoneNumber = contact.displayName!;
      this.setState({ dialpadInputValue: phoneNumber });
      if (contact?.channels?.length === 0) {
        callTarget = {phoneNumber: phoneNumber};
      } else {
        callTarget = {microsoftTeamsUserId: phoneNumber};
      }
      if (this.props.callAgent) {
        const outboundCall = await this.props.callAgent.startCall(callTarget);
        const currentCallId = outboundCall.id;
        this.setState({currentCallId: currentCallId});
          callList[currentCallId] = outboundCall;
        this.setState({ isOnCall: true });
        this.props.resize(this.minAppHeight);
        const callObject = {
          phoneNumber: phoneNumber,
          kind: 'phoneNumber',
          id: outboundCall.id,
        };
        callList[currentCallId].callObject = callObject;
          this.setState({callList: callList});
        this.setState({currentCallState: INTERACTION_STATES.Initiated});
        this.prepareAndSetInteraction(callList[currentCallId]);
        outboundCall.on('stateChanged', async () => {
          if (outboundCall.state === 'Connected') {
            this.setState({ currentCallState: INTERACTION_STATES.Connected });
            this.prepareAndSetInteraction(callList[currentCallId]);
            this.setState({ dialpadInputValue: '' });
          } else if (outboundCall.state === 'Disconnected') {
            this.setState({currentCallState: INTERACTION_STATES.Disconnected});
            this.setState({ isOnCall: false });
            setAppHeight(this.minAppHeight);
            this.prepareAndSetInteraction(callList[currentCallId]);
          }
        });
      }
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

   /**
   * Create a CAD object
   * @param {any} rawCADData - call information data
   * @memberof CallComponent
   */
  constructCADObject(rawCADData: any) {
    const functionName = 'constructCADObject';
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);
      if (rawCADData) {
        const cadObject: any = {};
        for (const cad in rawCADData) {
          if (rawCADData.hasOwnProperty(cad)) {
            cadObject[cad] = {
              DevName: '',
              DisplayName: '',
              Value: rawCADData[cad],
            };
          }
        }
        this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
        return cadObject;
      } else {
        this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
        return undefined;
      }
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
   * Accept inbound call
   *
   * @memberof CallComponent
   */
  @bind
  async acceptCall() {
    const functionName = 'acceptCall';
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);
      const callList = this.state.callList;
      callList[this.state.currentCallId] = await this.state.incomingCallList[this.state.currentCallId].accept();
      this.setState({ callList: callList });
      this.setState({ isOnCall: true });
      this.setState({ incomingCallAlert: false });
      this.setState({ currentCallState: INTERACTION_STATES.Connected });
      this.prepareAndSetInteraction(callList[this.state.currentCallId]);
      this.props.resize();

      this.transcriptionHandler(this.state.callList[this.state.currentCallId]);

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  @bind
  async blindTransfer() {
    const functionName = 'blindTransfer';
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      const callList = this.state.callList;
      this.transferAgent = callList[this.state.currentCallId].feature(Features.Transfer);
      this.transferAgent.on('transferRequested', () => {
        this.props.logger.log(LOG_LEVEL.Debug, functionName, `CallComponent : transfer requested : Call id`, this.state.currentCallId);
      });

      const response = await contextualOperation(
        CONTEXTUAL_OPERATION_TYPE.BlindTransfer,
        CHANNEL_TYPES.Telephony
      )
      .catch((error) => {
        this.props.logger.log(
          LOG_LEVEL.Error,
          functionName,
          `TEAMS - CallComponent - contextualOperation - Error: ${error.message || error
          }`
        );
      });

      let transfer: { state: string; error: { code: any; subCode: any; }; on: (arg0: string, arg1: () => void) => void; };
      let teamsId = '';
      if (response) {
        for (const channel in response.channels) {
          if (response.channels[channel].idName === 'Microsoft Teams User Id') {
            teamsId = response.channels[channel].id;
          }
        }
        if (teamsId !== '') {
          let id = {microsoftTeamsUserId: teamsId};
          const target: TransferToParticipantLocator = {
            targetParticipant: id
          }
          const options: TransferToParticipantOptions = { disableForwardingAndUnanswered : false};
          transfer = await this.transferAgent.transfer(target, options);
          transfer.on('stateChanged', () => {
            try{
              if (transfer.state === 'Transferred') {
                callList[this.state.currentCallId].hangUp();
              }
            } catch(error) {
              this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
            }
          });
        } else {
          this.props.logger.log(LOG_LEVEL.Debug, functionName, `CallComponent : User not associated to a teams account`);
          sendNotification('User attempting to blind Transfer to is not a Microsoft Teams user');
        }
      }

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
   * Reject inbound call
   *
   * @memberof CallComponent
   */
  @bind
  async rejectCall() {
    const functionName = 'rejectCall';
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      const call = await this.state.incomingCallList[this.state.currentCallId];
      call.reject();
      this.setState({ isOnCall: false });
      this.setState({ incomingCallAlert: false });
      this.setState({ currentCallState: INTERACTION_STATES.Disconnected });
      this.prepareAndSetInteraction(this.state.callList[this.state.currentCallId]);
      this.props.resize();

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
   * End call
   *
   * @memberof CallComponent
   */
  @bind
  async endCall() {
    const functionName = 'endCall';
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      const call = await this.state.callList[this.state.currentCallId];
      call.hangUp({
          forEveryone: true
      });

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
   * Place the current call on hold
   *
   * @memberof CallComponent
   */
  @bind
  async holdCall() {
    const functionName = `holdCall`;
    try {
      const call = await this.state.callList[this.state.currentCallId];
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `Holding Call`, call);
      await call?.hold();
      this.setState({isCallOnHold: true});
      this.setState({currentCallState: INTERACTION_STATES.OnHold});
      this.prepareAndSetInteraction(call);

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
   * Unhold the current call
   *
   * @memberof CallComponent
   */
  @bind
  async resumeCall() {
    const functionName = `resumeCall`;
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

        const call = await this.state.callList[this.state.currentCallId];
        await call?.resume();
        this.setState({isCallOnHold: false});
        this.setState({currentCallState: INTERACTION_STATES.Connected});
        this.prepareAndSetInteraction(call);

        this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
      } catch (error) {
        this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
      }
  }

  /**
   * Mute the current call
   *
   * @memberof CallComponent
   */
  @bind
  async muteCall() {
    const functionName = `muteCall`;
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      const call = await this.state.callList[this.state.currentCallId];
      await call?.mute();
      this.setState({currentCallState: INTERACTION_STATES.Connected});
      this.prepareAndSetInteraction(call);

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
     * Unmute the current call
     *
     * @memberof CallComponent
     */
  @bind
  async unmuteCall() {
    const functionName = `unmuteCall`;
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      const call = await this.state.callList[this.state.currentCallId];
      await call?.unmute();
      this.setState({currentCallState: INTERACTION_STATES.Connected});
      this.prepareAndSetInteraction(call);

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
     * Send DTMF tones
     * @param {string} digits - digit to pressed
     * @memberof CallComponent
     */
  @bind
  async handleDTMF(digits: string) {
    const functionName = `handleDTMF`;
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);
      const call = await this.state.callList[this.state.currentCallId];
      if(call && call.state === 'Connected') {
        call.sendDtmf(digits);
        this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
      }
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
     * Open DTMF input for agents within the keypad
     * @memberof CallComponent
     */
  async openDTMF() {
    const functionName = `openDTMF`;
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      let div = document.getElementById('greyLayer')?.style;
      div!.display = 'block';

      await contextualOperation(
        CONTEXTUAL_OPERATION_TYPE.DTMF,
        CHANNEL_TYPES.Telephony, async (contact: IContextualContact) => {
          await this.handleDTMF(DTMF_MAP[contact.uniqueId]);
        }
      )
      .then(async (contact) => {})
      .catch((error) => {
        this.props.logger.log(
          LOG_LEVEL.Error,
          functionName,
          `TEAMS - CallComponent - contextualOperation - Error: ${error.message || error
          }`
        );
      });
      div!.display = 'none';

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

   /**
   * This function retrieves the list of interactions from local storage and checks if there's
   * any active interaction when the app loads, if so, it sends an disconnected interaction to the CRM
   * @memberof CallComponent
   */
  checkForExpiredCalls() {
    const functionName = `checkForExpiredCalls`;
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);
      this.storageService.syncWithLocalStorage();
      this.setState({interactionList: this.storageService.interactionList});
      const keys = Object.keys(this.storageService.interactionList);
      if(keys.length > 0) {
        keys.forEach(key => {
          const interaction = this.storageService.interactionList[key];
          interaction.state = INTERACTION_STATES.Disconnected;
          setInteraction(interaction);
          this.storageService.removeInteraction(interaction.scenarioId);
        });
      }
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  // TODO: Create a teams interaction object instead of using any for params
  /**
   * Before an interaction is send to the DaVinci Framework, the interaction needs to be formulated with
   * the proper call data. If there is any CAD, the CAD will be added to the details of the interaction.
   *
   * @memberof CallComponent
   */
  @bind
  async prepareAndSetInteraction(params: any, channel = CHANNEL_TYPES.Telephony,  state?: INTERACTION_STATES, CAD?: any): Promise<void> {
    const functionName = 'prepareAndSetInteraction';
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      let direction: INTERACTION_DIRECTION_TYPES;
      const details = new RecordItem('', '', '');
      let userDetails: IUserDetails;
      let newInteraction: IInteraction;

      if ((params?.callerInfo?.identifier?.kind === 'microsoftTeamsUser')
          || (this.isPhoneNumber(params?.callObject?.phoneNumber) === false)
      ) {
        direction = INTERACTION_DIRECTION_TYPES.Internal;
      } else {
        direction = params.direction ? TEAMS_DIRECTION_MAP[params.direction as keyof typeof TEAMS_DIRECTION_MAP] : INTERACTION_DIRECTION_TYPES.Inbound;
      }

      let callInfo;
      if (direction === INTERACTION_DIRECTION_TYPES.Inbound) {
        callInfo = {
          interaction_id: params.id,
          scenario_id: params.id,
          callType: params.callerInfo.identifier?.kind,
          phoneNumber: params.callerInfo.identifier.phoneNumber,
          CAD: params.callerInfo,
        };
      } else if (direction === INTERACTION_DIRECTION_TYPES.Outbound) {
        callInfo = {
          interaction_id: params.callObject.id,
          scenario_id: params.callObject.id,
          callType: params.callObject.kind,
          phoneNumber: params.callObject.phoneNumber,
          CAD: params.callObject,
        };
      } else {
        callInfo = {
          interaction_id: params.id,
          scenario_id: params.id,
          callType: params?.callerInfo?.identifier?.kind ?? params?.callObject?.kind,
          phoneNumber: params?.callerInfo?.identifier?.microsoftTeamsUserId ? await getDisplayName(this.props.authProvider, params.callerInfo.identifier.microsoftTeamsUserId) : await getDisplayName(this.props.authProvider, params.callObject.phoneNumber),
          CAD: {}
        };
      }

      const cad = Object.assign({}, callInfo?.CAD);
      const cadObject = this.constructCADObject(cad);
      for (const callData in cadObject) {
        details.setField(callData, '', '', cadObject[callData]);
      }

      if (channel === CHANNEL_TYPES.Telephony && (callInfo?.callType === 'phoneNumber' || callInfo?.callType === 'microsoftTeamsUser')) {
        details.setPhone('', '', callInfo?.phoneNumber);
        if (this.agentPhoneNumber) {
          details.setDialedPhone('', '', this.agentPhoneNumber);
        }
      }

      if (CAD !== undefined) {
      // Checking if transcript is for an agent or client.
        if (CAD.relation === 'END_USER') {
          userDetails = {
            email: params.email !== undefined ? params.email : '',
            username: params.username !== undefined ? params.username : '',
            firstName: params.firstName !== undefined ? params.firstName : '',
            lastName: params.lastName !== undefined ? params.lastName : '',
            attributes: params.attributes !== undefined ? params.attributes : [],
            profiles: [],
            userType: USER_TYPES.Client,
          }
        } else {
          userDetails = this.props.agentDetails;
          userDetails.userType = USER_TYPES.Agent;
        }
        const date = new Date();
        const currentTranscript: ITranscript = {
          id: date.getTime().toString(),
          data: CAD?.transcript,
          isComplete: false,
          context: userDetails,
          timestamp: date,
        };

          newInteraction = {
            interactionId: callInfo?.interaction_id,
            scenarioId: callInfo?.scenario_id,
            state: state,
            channelType: channel,
            direction: direction,
            details: details,
            transcripts: currentTranscript
          };

        } else {
          newInteraction = {
            interactionId: callInfo?.interaction_id,
            scenarioId: callInfo?.scenario_id,
            state: this.state.currentCallState,
            channelType: channel,
            direction: direction,
            details: details,
          };
        }

        this.props.logger.log(LOG_LEVEL.Debug, functionName, `CallComponent : Interaction Created : `, newInteraction);

        try {
          setInteraction(newInteraction);
        } catch (error) {
          this.props.logger.log(LOG_LEVEL.Critical, functionName, `setInteraction Error`, error);
        }

        if(this.state.currentCallState === INTERACTION_STATES.Disconnected) {
          this.setState({isOnCall: false, isCallOnHold: false});

          this.storageService.removeInteraction(newInteraction.scenarioId);
        } else {
          this.storageService.addInteraction(newInteraction);
        }
        this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
   * Check to see if input value is a phone number
   * @memberof CallComponent
   */
  isPhoneNumber(microsoftCallIdentifier: string) {
    const functionName = 'isPhoneNumber';
    try{
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      if (microsoftCallIdentifier?.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im) === null) {
        this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END : Value not a phone number : ${microsoftCallIdentifier}`);
        return false
      }

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END : Value is a phone number`);
      return true;
    } catch(error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
   * Retrieve user input from the dialpad
   * @memberof CallComponent
   */
  onChange(input: string) {
    const functionName = 'onChange';
    try{
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      this.setState({dialpadInputValue: input});

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch(error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

    /**
   * Handles DTMF event from the dialpad
   * @param {DtmfTone} dtmfTone - The dtmfTone to be sent
   * @memberof CallComponent
   */
  onSendDtmfTone(dtmfTone: DtmfTone): Promise<void> {
    const functionName = 'onSendDtmfTone';
    try{
      this.handleDTMF(dtmfTone)
      return Promise.resolve();
    } catch(error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
      return Promise.reject();
    }
  }

  /**
   * Handles the show dialpad btn
   * @param {boolean} displayDialPad - Determines whether the DialPad should be displayed
   * @memberof CallComponent
   */
  @bind
  handleDial(displayDialPad: boolean) {
    const functionName = 'handleDial';
    try{
      this.setState({showDialPad: displayDialPad });
    } catch(error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  async transcriptionHandler(call: Call) {
    const functionName = 'transcriptionHandler';
    try {
      this.props.logger.log(LOG_LEVEL.Debug, functionName, 'Initializing transcription for call.', [call, this.remoteAudioStarted, call.direction]);
      // Make sure call is defined before creating event listener; should only happen once; only supports inbound calls
      if (call && call.direction === 'Incoming') {
        this.remoteAudioStarted = true;
        // Only Transcribe if configured; configured in Creators Studio; retrieved from an object in index.tsx
        if (this.props.transcriptionConfig.Enabled === true) {
          // The remoteAudioStreamArray shows what was added/removed to remoteAudioStreams
          // Begin listening to the remoteAudioStreamsUpdated event
          call.on('remoteAudioStreamsUpdated', this.beginTranscription.bind(this));
        }
      }
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, 'Error initializing transcription.', error);
    }
  }

  @bind
  async beginTranscription() {
    const functionName = 'beginTranscription';
    try {
      let call: Call = await this.state.callList[this.state.currentCallId];

      // Configurations needed to utilize Azure Cognitive Services
      const speechConfig: SpeechConfig = SpeechConfig.fromSubscription(this.props.transcriptionConfig.SpeechKey, this.props.transcriptionConfig.SpeechRegion);

      // Grab the MediaStream object from the remoteAudioStreams array
      // remoteAudioStreams is part of the call object
      // It contains an array of remoteAudioStream objects
      // getMediaStream() gets the MediaStream associated with the RemoteAudioStream
      const mediaStream: MediaStream | undefined = await call?.remoteAudioStreams[0].getMediaStream(); // This only grabs one audio stream

      // Create AudioConfig based off MediaStream - clientAudio
      const clientAudioConfig: AudioConfig = AudioConfig.fromStreamInput(mediaStream as MediaStream);

      // Create AudioConfig based off Agent's microphone
      const agentAudioConfig: AudioConfig = AudioConfig.fromDefaultMicrophoneInput();

      // Create SpeechRecognizer based on the SpeechConfig and AudioConfig for the client
      const remoteSpeechRecognizer: SpeechRecognizer = new SpeechRecognizer(speechConfig, clientAudioConfig);

      // Create SpeechRecognizer based on the SpeechConfig and AudioConfig for the agent
      const agentSpeechRecognizer: SpeechRecognizer = new SpeechRecognizer(speechConfig, agentAudioConfig);

      // Begin listening to transcriptions from the remote
      remoteSpeechRecognizer.startContinuousRecognitionAsync(() => {
        const functionName = 'startContinuousRecognitionAsync';
        try {
          this.props.logger.log(LOG_LEVEL.Trace, functionName, 'Initializing remote transcription.');
          call?.on('remoteAudioStreamsUpdated', () => {
            const functionName = 'remoteAudioStreamsUpdated';
            try {
              if (call?.remoteAudioStreams.length === 0) {
                this.props.logger.log(LOG_LEVEL.Trace, functionName, 'Ending remote transcription');
                remoteSpeechRecognizer.stopContinuousRecognitionAsync();
              } else {
                this.props.logger.log(LOG_LEVEL.Loop, functionName, 'Remote audio updated');
              }
            } catch (error) {
              this.props.logger.log(LOG_LEVEL.Error, functionName, `Remote audio update error.`, error);
            }
          });
        } catch (error) {
          this.props.logger.log(LOG_LEVEL.Error, functionName, `Failed to initialize remote transcription.`, error);
        }
      });

      // Begin listening to transcriptions from the agent
      agentSpeechRecognizer.startContinuousRecognitionAsync(() => {
        const functionName = 'startContinuousRecognitionAsync';
          try {
            call?.on('remoteAudioStreamsUpdated', () => {
              const functionName = 'clientAudioStreamsUpdated';
              try {
                if (call?.remoteAudioStreams.length === 0) {
                  this.props.logger.log(LOG_LEVEL.Trace, functionName, 'Ending client transcription');
                  agentSpeechRecognizer.stopContinuousRecognitionAsync();
                } else {
                  this.props.logger.log(LOG_LEVEL.Loop, functionName, 'Client audio updated');
                }
              } catch (error) {
                this.props.logger.log(LOG_LEVEL.Error, functionName, `Client audio update error.`, error);
              }
            });
          } catch (error) {
            this.props.logger.log(LOG_LEVEL.Error, functionName, `Failed to initialize agent transcription.`, error);
          }
        }
      );

      // Constantly firing as words are detected
      remoteSpeechRecognizer.recognizing = (s, e) => {
        try {
          console.log('Remote: ' + e.result.text);
          console.log('Remote Offset in Ticks: ' + e.result.offset);
          console.log('Remote Duration in Ticks: ' + e.result.duration);
        } catch (error) {
          console.log('Failed to get Remote Audio ');
        }
      };

      // Only triggered when sentences are completed (pause in audio) or based on a configured time.
      // Default configured time is 15 seconds.
      remoteSpeechRecognizer.recognized = async (s, e) => {
        try {
          if (e.result.text !== undefined && e.result.text !== '') {
            let text: string = e.result.text;
            // const clientText: string[] = [text];
            this.sendTranscription(call, 'END_USER', text);
          }
          console.log('Client - RECOGNIZED:  ' + e.result.text);
        } catch (error) {
          console.log('Failed to Recognize Remote Audio ');
        }
      };

      // Constantly firing as words are detected
      agentSpeechRecognizer.recognizing = (s, e) => {
        try {
          console.log('Client: ' + e.result.text);
          console.log('Client Offset in Ticks: ' + e.result.offset);
          console.log('Client Duration in Ticks: ' + e.result.duration);
        } catch (error) {
          console.log('Failed to get Client Audio ');
        }
      };

      // Only triggered when sentences are completed (pause in audio) or based on a configured time.
      // Default configured time is 15 seconds.
      agentSpeechRecognizer.recognized = (s, e) => {
        try {

          if (e.result.text !== undefined && e.result.text !== '') {
            this.sendTranscription(call, 'HUMAN_AGENT', e.result.text);
          }
          console.log('Agent - RECOGNIZED:  ' + e.result.text);
        } catch (error) {
          console.log('Failed to Recognize Agent Audio ');
        }
      };
        // Stop listening to remoteAudioStreamsUpdated event
        call?.off('remoteAudioStreamsUpdated', this.beginTranscription);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  @bind
  private async sendTranscription(call: Call, relation: string, transcript: String) {
    const functionName = 'sendTranscription';
    try {
      const CAD: object = {
        relation,
        transcript,
      };

      let callInteractionState = this.teamsStateToInteractionState(call.state);
      this.prepareAndSetInteraction(call, CHANNEL_TYPES.Telephony, callInteractionState, CAD);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  };

    /**
   * Before an interaction is send to the DaVinci Framework, the interaction needs to be formulated with
   * the proper call data. If there is any CAD, the CAD will be added to the details of the interaction.
   *
   * @memberof CallComponent
   */
  teamsStateToInteractionState(state: string): INTERACTION_STATES {
    switch (state) {
      case 'Alerting':
        return INTERACTION_STATES.Alerting;
      case 'Connected':
        return INTERACTION_STATES.Connected;
      case 'Disconnected':
        return INTERACTION_STATES.Disconnected;
      case 'Hold':
        return INTERACTION_STATES.OnHold;
      default:
        return INTERACTION_STATES.Connected;
    }
  }

  @bind
  render() {
    const functionName = 'render';
    try {
      if (this.state.incomingCallAlert) {
          this.props.resize();
      }
      return (
          // #Home-CallComponent
          <div id={this.props.id}>
              {!this.state.isOnCall ? (
              <FluentThemeProvider>
                  {this.state.incomingCallAlert && (
                    <div style={{display: 'flex'}}>
                      <div className='incomingCallToast'>
                        <IncomingCallToast
                            callerName={this.displayName}
                            alertText={'incoming Call'}
                            onClickAccept={this.acceptCall}
                            onClickReject={this.rejectCall}
                        />
                      </div>
                    </div>
                  )}
              </FluentThemeProvider>
            )  :
              <><div className='layer' id='greyLayer' style={{ position: 'absolute', top: '0px', left: '0px', zIndex: '2', background: 'white', opacity: '0.7', width: '100%', height: '100%', display: 'none' }}></div><div>
              <VideoTile
                styles={this.videoTileStyles}
                displayName={this.displayName}
                renderElement={null}
                isMirrored={true}
                personaMinSize={72} />
            </div></>
                  }
                  {this.state.isOnCall && <ControlBar styles={this.controlBarStyles}>
                      <MicrophoneButton
                          checked={this.state.audioButtonChecked}
                          onClick={() => {
                              this.setState({ audioButtonChecked: !this.state.audioButtonChecked });
                              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                              this.state.callList[this.state.currentCallId].isMuted ? this.unmuteCall() : this.muteCall();
                          }}
                      />
                      {!this.state.isCallOnHold && <ControlBarButton
                        key={'openDTMF'}
                        onClick={this.openDTMF}
                        onRenderIcon={() => <Dialpad20Filled key={'dtmfIconKey'} primaryFill="currentColor" />}
                      />}
                      {this.state.isCallOnHold && <ControlBarButton
                        key={'initiateBlindTransfer'}
                        onClick={this.blindTransfer}
                        onRenderIcon={() => <CallTransfer20Filled key={'transferIconKey'} primaryFill="currentColor" />}
                      />}
                      {!this.state.isCallOnHold && <ControlBarButton
                        key={'holdCall'}
                        onClick={this.holdCall}
                        aria-label='Hold Call'
                        onRenderIcon={() => <Pause20Filled key={'holdIconKey'} primaryFill="currentColor"/>}
                      />}
                      {this.state.isCallOnHold && <ControlBarButton
                        key={'resumeCall'}
                        onClick={this.resumeCall}
                        onRenderIcon={() => <Pause20Regular key={'resumeIconKey'} primaryFill="currentColor" />}
                      />}
                      <EndCallButton
                          onClick={this.endCall}
                      />
                  </ControlBar>
              }
          </div>
      );
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }
}
export default CallComponent;
