import { IInteraction, LOG_LEVEL } from '@amc-technology/davinci-api';

import React from 'react';

interface props {
    logger: any;
}

export class StorageService extends React.Component<props> {
    logger: any;
    public interactionList: {
        [scenarioId: string]: IInteraction;
    };

    private readonly className = 'StorageService';
    constructor(props:any) {
        super(props);
        this.logger = props.logger;
        this.interactionList = {};
    }

     /**
   * Add interaction to interactionList
   * @param {IInteraction} interaction - key to be mapped to the interaction
   * @memberof StorageService
   */
    public addInteraction(interaction: IInteraction) {
        const functionName = `addInteraction`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `StorageService : START`);
            if (!this.interactionList) {
                this.interactionList = {};
            }
            if (interaction.scenarioId !== undefined) {
                this.interactionList[interaction.scenarioId] = interaction;
            }
            this.storeToLocalStorage();
        } catch (error) {
            this.logger.log(LOG_LEVEL.Error,functionName, ` : StorageService : ERROR`, error);
        }
      }

    /**
   * Remove interaction from interactionList
   * @param {string} scenarioId - key mapped to the interaction
   * @memberof StorageService
   */
    public removeInteraction(scenarioId: string) {
        const functionName = `removeInteraction`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `StorageService : START`);
            if (this.interactionList[scenarioId]) {
                delete this.interactionList[scenarioId];
            }
            this.storeToLocalStorage();
        } catch (error) {
            this.logger.log(LOG_LEVEL.Error,functionName, ` : StorageService : ERROR`, error);
        }
    }

    /**
   * Retrieve data from local storage and sync
   *
   * @memberof StorageService
   */
    public syncWithLocalStorage() {
        const functionName = `syncWithLocalStorage`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `StorageService : START`);
            const interactionRecord = localStorage.getItem('interaction');
            if(interactionRecord) {
                const browserStorage = JSON.parse(interactionRecord);
                this.interactionList = browserStorage.interactionList;
            }
        } catch (error) {
            this.logger.log(LOG_LEVEL.Error,functionName, ` : StorageService : ERROR`, error);
        }
    }

    /**
   * Stores data in local storage
   *
   * @memberof StorageService
   */
    public storeToLocalStorage() {
        const functionName = `storeToLocalStorage`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `StorageService : START`);
            const prevInteractionRecord = localStorage.getItem('interaction');
            const interactionRecord = JSON.stringify({
                interactionList: this.interactionList
            });
            this.logger.log(LOG_LEVEL.Trace, functionName, `: Storing to Local Storage. Scenario before update :`, prevInteractionRecord);
            localStorage.setItem('interaction', interactionRecord);
            this.logger.log(LOG_LEVEL.Trace, functionName, ` DONE : Storing to Local Storage. Scenario after update :`, interactionRecord);
        } catch (error) {
            this.logger.log(LOG_LEVEL.Error, functionName, ` : StorageService : ERROR`, error);
        }
    }


}
