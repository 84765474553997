import { AzureLogger, setLogLevel } from '@azure/logger';
import { CallClient, TeamsCallAgent } from "@azure/communication-calling";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";

import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { CommunicationIdentityClient } from "@azure/communication-identity";
import { LOG_LEVEL } from "@amc-technology/davinci-api";
import LoggerService from "./logger";

class UserComponent {
    instance: any;
    authProvider: AuthCodeMSALBrowserAuthenticationProvider;
    callAgent: TeamsCallAgent;
    clientId: string;
    logger: LoggerService;
    connectionString: string;
    tokenCredential: any;
    userId: string;
    accounts: any;
    azureLogger: AzureLogger;

    constructor(instance:any, connectionString: string, clientId: string, logger: LoggerService, enableAzureLogs: boolean = false) {
        const functionName = 'UserComponent';
        try {
            if (enableAzureLogs) {
                setLogLevel('verbose');
                AzureLogger.log = (...args) => {
                    logger.log(LOG_LEVEL.Loop, 'Azure Logger', 'ACS Logger', args);
                }
            }

            this.instance = instance;
            this.clientId = clientId;
            this.logger = logger;

            this.connectionString = connectionString;
            this.accounts = this.instance.getAllAccounts();
            logger.log(LOG_LEVEL.Debug, functionName, 'Accounts from signed in MSAL instance.', this.accounts);
            this.userId = this.accounts[0].localAccountId;

            this.authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
                this.instance as PublicClientApplication,
                {
                    account: this.accounts[0],
                    scopes: ["https://graph.microsoft.com/.default"] ,
                    interactionType: InteractionType.Popup
                }
            );
        } catch (error) {
            this.logger.log(LOG_LEVEL.Error, functionName, 'Error occurred while fetching data', error);
        }
    }


    async createCallAgent() {
        const functionName = 'createCallAgent';
        try {
            this.logger.log(LOG_LEVEL.Debug, functionName, 'Creating call agent using account.', this.accounts[0]);
            const request = {
                scopes: ['https://auth.msft.communication.azure.com/Teams.ManageCalls', 'https://auth.msft.communication.azure.com/Teams.ManageChats'],
                account: this.accounts[0],
            };
            const response = await this.instance.acquireTokenSilent(request);
            const accessToken = response.accessToken;
            const client = new CommunicationIdentityClient(this.connectionString);
            let teamsToken = await client.getTokenForTeamsUser({
                teamsUserAadToken: accessToken,
                clientId: this.clientId,
                userObjectId: this.accounts[0].localAccountId,
            });

            let tokenCredential = new AzureCommunicationTokenCredential(teamsToken.token);
            const callClient = new CallClient({ logger: this.azureLogger });
            this.logger.log(LOG_LEVEL.Debug, functionName, 'Call client created successfully', callClient);
            this.callAgent = await callClient.createTeamsCallAgent(tokenCredential);
            this.logger.log(LOG_LEVEL.Debug, functionName, 'Call agent created successfully', this.callAgent);
            return this.callAgent;
        } catch (error) {
            this.logger.log(LOG_LEVEL.Error, functionName, 'Error occurred while fetching data', error);
        }
    }

    getUserId() {
        return this.userId;
    }

    getAuthProvider() {
        return this.authProvider;
    }
}

export default UserComponent;
