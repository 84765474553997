export enum DTMFdigits {
    ZERO = 'Num0',
    ONE = 'Num1',
    TWO = 'Num2',
    THREE = 'Num3',
    FOUR = 'Num4',
    FIVE = 'Num5',
    SIX = 'Num6',
    SEVEN = 'Num7',
    EIGHT = 'Num8',
    NINE = 'Num9',
    STAR = 'Star',
    POUND = 'Pound'
}






