import { Client } from "@microsoft/microsoft-graph-client";
import {
  AuthCodeMSALBrowserAuthenticationProvider,
} from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";


let graphClient: Client | undefined = undefined;

  function ensureClient(authProvider: AuthCodeMSALBrowserAuthenticationProvider) {
    if (!graphClient) {
        graphClient = Client.initWithMiddleware({
          authProvider: authProvider
        });
    }
    return graphClient; 
  }

// Return the /me API endpoint result as a User object
  export async function getUser(authProvider: AuthCodeMSALBrowserAuthenticationProvider): Promise<any> {
    ensureClient(authProvider);
    const user: any = await graphClient!.api('/me')
      .get();
  
    return user;
  }

  export async function getPresence(authProvider: AuthCodeMSALBrowserAuthenticationProvider): Promise<any> {
    ensureClient(authProvider);
    const presence: any = await graphClient!.api('/me/presence').get();
    return presence;
  }

  export async function setPresence(authProvider: AuthCodeMSALBrowserAuthenticationProvider, presence: string[], userId: string, clientId: string) {
    ensureClient(authProvider);
    try {
        const setPresence = {
          sessionId: clientId,
          availability: presence[0],
          activity: presence[1],
          expirationDuration: 'PT2H'
        };
          await graphClient!.api(`/users/${userId}/presence/setPresence`)
          .post(setPresence);
        
      } catch (error) {
        throw(error);
      }
  }

  export async function getDisplayName(authProvider: AuthCodeMSALBrowserAuthenticationProvider, userId: string) {
    ensureClient(authProvider);
    const user: any = await graphClient!.api(`/users/${userId}`)
      .get();
  
    return user.displayName;
  }
    
  