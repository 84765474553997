import './index.css';

import { AccountInfo, PublicClientApplication } from '@azure/msal-browser';
import { IUserDetails, LOG_LEVEL, NOTIFICATION_TYPE, enableClickToDial, getConfig, getUserDetails, initializeComplete, sendNotification, setAppHeight } from '@amc-technology/davinci-api';

import Home from './Home/Home';
import LoggerService from './logger';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { TeamsCallAgent } from '@azure/communication-calling';
import UserComponent from './UserComponent';

/**
 * Initializes the app by initializing the logger, AMC config, and MSAL. Once initialized, the app is rendered.
 */
const initializeApp = async () => {
  const functionName = 'initializeApp';
  const logger = await initLogger();
  try {
    const csConfig = await initAmcConfig(logger);
    setAppHeight(csConfig.minAppHeight);
    logger.log(LOG_LEVEL.Debug, functionName, 'Finished initializing, attempting to sign in to Teams.');
    tryLogin(csConfig, logger);
  } catch (error) {
    logger.log(LOG_LEVEL.Error, functionName, 'Error initializing app', error);
  }
};
/**
 * Initialize logger service to be passed as props to all child components
 * @return {Promise<LoggerService>}
 */
const initLogger = async (): Promise<LoggerService> => {
  try {
    const loggerService = new LoggerService();
    await loggerService.initializeLogger();
    await initializeComplete(loggerService.logger);
    loggerService.log(LOG_LEVEL.Debug, 'initLogger', 'Logger initialized successfully')
    return loggerService;
  } catch(error) {
    console.log('Error initializing logger', error);
  }
}
/**
 * Initialize AMC config from Studio and user details from custom user attributes
 *
 * @param {LoggerService} logger
 * @return {csConfig} csConfig
 */
const initAmcConfig = async (logger: LoggerService): Promise<any> => {
  const functionName = 'initAmcConfig';
  try {
    enableClickToDial(true);

    const userDetails: IUserDetails = await getUserDetails();
    logger.log(LOG_LEVEL.Loop, functionName, 'User Details', userDetails);

    const configAMC: any = await getConfig();
    logger.log(LOG_LEVEL.Loop, functionName, 'Config from Studio', configAMC);

    const csConfig = {
      channelToDaVinci: configAMC.ChannelToDaVinci.variables,
      DaVinciToChannel: configAMC.DaVinciToChannel.variables,
      appName: configAMC.name,
      clientID: configAMC.AzureCSConfig.variables.ClientID,
      connectionString: configAMC.AzureCSConfig.variables.ConnectionString ?? '',
      transcription: configAMC.Transcription.variables,
      cognitiveServices: configAMC.AzureCSConfig.variables,
      minAppHeight: configAMC?.variables?.minAppHeight ?? 5,
      tenant: configAMC.AzureCSConfig.variables.TenantID ?? '',
      clientId: configAMC.AzureCSConfig.variables.ClientID ?? '',
      url: configAMC?.variables?.URL ?? '',
      maxLoginAttempts: configAMC?.AzureCSConfig?.variables?.maxLoginAttempts ?? 5,
      userDetails: userDetails ?? '[]',
      presencePollingInterval: configAMC?.variables?.presencePollingInterval ?? 5000,
      enableAzureLogs: configAMC?.variables?.enableAzureLogs ?? false,
    };
    logger.log(LOG_LEVEL.Debug, functionName, 'Configs being used for props.', csConfig);
    return csConfig;
  } catch(error) {
    logger.log(LOG_LEVEL.Critical, functionName, 'Error initializing AMC config', error);
    sendNotification('Error initializing Teams configs, please contact your administrator.', NOTIFICATION_TYPE.Error);
    throw new Error('Error initializing AMC config');
  }
}
/**
 * Attempts to login to Teams using MSAL up to max configured attempts. Once a successful login is made, the app is rendered.
 *
 * @param {*} csConfig
 * @param {LoggerService} logger
 * @param {number} [loginAttempts=0]
 * @return {*}
 */
const tryLogin = async (csConfig: any, logger: LoggerService, loginAttempts: number = 0): Promise<void> => {
  const functionName = 'tryLogin';
  try {
    const pca = initMsal(csConfig, logger);
    logger.log(LOG_LEVEL.Debug, functionName, 'MSAL initialized successfully', pca);

    await getAccounts(pca, logger);

    const user = new UserComponent(pca, csConfig.connectionString, csConfig.clientID, logger, csConfig.enableAzureLogs);
    logger.log(LOG_LEVEL.Debug, functionName, 'User component initialized successfully', user);

    const callAgent: TeamsCallAgent = await user.createCallAgent();
    logger.log(LOG_LEVEL.Debug, functionName, 'Call agent created successfully', callAgent);

    if (callAgent !== undefined) {
      renderApp(pca, logger, csConfig, user, callAgent);
    } else {
      logger.log(LOG_LEVEL.Error, functionName, 'Error creating call agent for user.', user)
    }
  } catch (error) {
    logger.log(LOG_LEVEL.Error, functionName, 'Error trying login', error);
    loginAttempts++;
    if (loginAttempts <= csConfig.maxLoginAttempts) {
      return await tryLogin(csConfig, logger, loginAttempts);
    } else {
      logger.log(LOG_LEVEL.Critical, functionName, 'Max login attempts exceeded.', { csConfig, loginAttempts });
      sendNotification('Max login attempts exceeded. Please refresh the page.', NOTIFICATION_TYPE.Error);
      throw new Error('Max login attempts exceeded.');
    }
  }
}
/**
 * Initializes MSAL with the provided config Studio
 *
 * @param {*} csConfig
 * @param {LoggerService} logger
 * @return {PublicClientApplication} pca
 */
const initMsal = (csConfig: any, logger: LoggerService): PublicClientApplication => {
  const functionName = 'initMsal';
  try {
    const pca = new PublicClientApplication({
      auth: {
        clientId: csConfig.clientId,
        authority: `https://login.microsoftonline.com/${csConfig.tenant}`,
        redirectUri: csConfig.url
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true
      },
    });
    logger.log(LOG_LEVEL.Trace, functionName, 'MSAL initialized successfully', pca);
    return pca;
  } catch (error) {
    logger.log(LOG_LEVEL.Error, functionName, 'Error initializing MSAL', error);
    throw new Error('Error initializing MSAL');
  }
}
/**
 * Attempts to get accounts from MSAL. If no accounts are found, a login popup is opened. If multiple accounts are found, the first account is used.
 *
 * @param {PublicClientApplication} pca
 * @param {LoggerService} logger
 * @return {AccountInfo[]} accounts
 */
const getAccounts = async (pca: PublicClientApplication, logger: LoggerService): Promise<AccountInfo[]> => {
  const functionName = 'getAccounts';
  try {
    let accounts: AccountInfo[] = pca.getAllAccounts();
    if (accounts.length === 0) {
      logger.log(LOG_LEVEL.Trace, functionName, 'No accounts found. Attempting login popup.');
      await pca?.loginPopup({
        scopes: ['https://auth.msft.communication.azure.com/Teams.ManageCalls', 'https://auth.msft.communication.azure.com/Teams.ManageChats'],
      });
      accounts = pca.getAllAccounts();
      if (accounts.length > 1) {
        logger.log(LOG_LEVEL.Warning, functionName, 'Multiple accounts found. Using first account.', accounts);
      }
    } else {
      logger.log(LOG_LEVEL.Trace, functionName, 'Accounts found. No need to open pop up.');
    }
    logger.log(LOG_LEVEL.Debug, functionName, 'Accounts', accounts)
    return accounts;
  } catch (error) {
    logger.log(LOG_LEVEL.Error, functionName, 'Error handling popup.', error);
    throw new Error('Error handling popup.');
  }
}
/**
 * Renders the app with the provided props
 *
 * @param {PublicClientApplication} pca
 * @param {LoggerService} logger
 * @param {*} csConfig
 * @param {UserComponent} user
 * @param {TeamsCallAgent} callAgent
 */
const renderApp = (pca: PublicClientApplication, logger: LoggerService, csConfig: any, user: UserComponent, callAgent: TeamsCallAgent) => {
  const functionName = 'renderApp';
  try {
    ReactDOM.render(
      <MsalProvider instance={pca}>
        <React.StrictMode>
          <Home
            logger={logger}
            csConfig={csConfig}
            instance={pca}
            user={user}
            callAgent={callAgent}
            id="Home"
          />
        </React.StrictMode>
      </MsalProvider> as React.ReactElement,
      document.getElementById('AMC-Teams-Root') as HTMLElement
    );
  } catch (error) {
    logger.log(LOG_LEVEL.Error, functionName, 'Error rendering app', error);
  }
}

await initializeApp();
